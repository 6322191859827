<!-- 题库管理首页 -->
<template>
  <div>
    <div class="main">
      <div style="font-size: 26px; font-weight: bold">课程管理</div>
      <div>
        <!-- <div> -->
          <el-button
            type="primary"
            style="height: 34px; line-height: 10px; margin-right: 45px"
            class="cinput"
            @click='addcontent'
            >课程包管理</el-button
          >
         <el-button
            type="primary"
            style="height: 34px; line-height: 10px; margin-right: 45px"
            class="cinput"
            @click='addcontent2'
            >内容管理</el-button
          >
      </div>

     
     
    </div>
  </div>
</template>

<script>
import {contentpageList,contentsaveOrUpdate} from '../../apis/index'
export default {
  data() {
    return {
      total:0,
      pageIndex:1,
      pageSize:10,
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
      ],
      value: "",
      isshow: false,
      isshow2: true,
      isdisabled:true,
      currentPage: 1,
      radio: "4",
      input: "",
      tableData: [
        
      ],
    };
  },
  watch: {
    input(newdata, olddata) {
      console.log(newdata);
      if (newdata) {
        this.isshow = true;
      } else {
        this.input=''
        this.list()
        this.isshow = false;
      }
    },
    radio(newdata, olddata) {
      this.radio=newdata
      this.pageIndex=1
      this.list()
      // if (newdata == "2") {
      //   this.isshow2 = true;
      // } else {
      //   this.isshow2 = false;
      // }
    },
  },
  created(){
    // console.log(this.$route.query.id)
    let id=this.$route.query.id
    this.list(id)
  },
  //   beforeRouteLeave(to, from, next) {
  //   if(to.fullPath=='/videoAdd' || to.fullPath=='/contentAdd'){//添加页，就不缓存
  //     this.$route.meta.keepAlive=false
  //     setTimeout(() => {
  //       next()
  //     }, 100);
  //   }else{//详情页缓存
  //   console.log('我是详情缓存')
  //    this.$route.meta.keepAlive=true
  //     setTimeout(() => {
  //       next()
  //     }, 100);
  //   }
  //   // 导航离开该组件的对应路由时调用
  //   // 可以访问组件实例 `this`
  // },
  methods: {
    lookvideo(row){
      this.$router.push({path:'/videoAdd',query:row})
    },
    lookcontent(row){
      this.$router.push({path:'/contentAdd',query:row})
    },
   async list(id){//内容管理
      const params={
        pageIndex:this.pageIndex,
        pageSize:this.pageSize,
        contentType:this.radio,
        searchStr:this.input,
        courseId:id
      }
     const {data}=await contentpageList(params)
     if(data.code==200){
       this.tableData=data.data.records,
       this.total=Number(data.data.total)
     }
     console.log(data)
    },
    addcontent(){
    //   if(this.radio=='3'){
    //     this.$router.push({path:'/contentAdd'})
    //   }else{
    //     this.$router.push({path:'/videoAdd'})
    //   }
    //   this.$router.push({path:'/surveyPapge'})//问卷管理
        this.$router.push({path:'/courseList'})
    },
    addcontent2(){
        this.$router.push({path:'/content'})
    },
    search() {
      console.log("我是搜索");
    },
     handleSelectionChange(val) {//选中
        console.log(val)
        if(val.length>0){
          this.isdisabled=false
        }else{
          this.isdisabled=true
        }
      },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize=val
      this.list()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageIndex=val
      this.list()
    },
     dele(row) {
       console.log(row)
       const params={
         id:row.id,
         isValidity:0
       }
        this.$confirm('此操作将永久删除该项, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          contentsaveOrUpdate(params).then(res=>{
            if(res.data.code==200){
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.list()
            }else{
              this.$message({
                type: 'error',
                message: res.data.msg
              });
            }
          })
          
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
      },
       deleall() {
        this.$confirm('此操作将永久删除选中项, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
      }
  },
};
</script>

<style lang='less' scoped>
.main {
  // min-height: 800px;
  background: #fff;
  margin: 19px 0;
  padding: 23px;
  border-radius: 14px;
  height: 500px;
}
.main > div:first-child {
  font-size: 26px;
  // font-weight: bold;
}
.main > div:nth-child(2) {
  display: flex;
  justify-content: space-between;
  height: 34px;
  margin: 18px 0;
  div {
    display: flex;
    img {
      width: 25px;
      height: 25px;
    }
  }
}
/deep/.el-radio-button__inner {
  height: 34px;
  line-height: 10px;
}
.cinput /deep/.el-input__inner {
  height: 34px;
  line-height: 10px;
}
/deep/.el-button--primary {
  height: 34px; line-height: 10px; 
}
/deep/.el-table::before {
  height: 0;
}
.ac /deep/.el-input__inner {
  border: none;
  background: #f7f7f7;
  // margin-right: 11px;
}
.red {
  color: #ff1b0b;
}
</style>
