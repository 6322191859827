import { render, staticRenderFns } from "./courseManage.vue?vue&type=template&id=4735fb98&scoped=true&"
import script from "./courseManage.vue?vue&type=script&lang=js&"
export * from "./courseManage.vue?vue&type=script&lang=js&"
import style0 from "./courseManage.vue?vue&type=style&index=0&id=4735fb98&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4735fb98",
  null
  
)

export default component.exports